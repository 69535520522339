import { quest, score, wallet } from "@fscrypto/domain";
import { questConfigs, questEcosystemSchema } from "@fscrypto/domain/quest";
import { Button } from "@fscrypto/ui";
import { LoaderFunctionArgs } from "@remix-run/node";
import { Link, useOutletContext, useRevalidator } from "@remix-run/react";
import { ArrowRightIcon } from "lucide-react";
import { scoreChainSchema } from "node_modules/@fscrypto/domain/src/score";
import { PendingRewardsCard, TotalValueEarnedCard } from "~/features/earn/components/rewards-pending-and-total";
import { Taxes } from "~/features/earn/components/taxes";
import { AddressSelector } from "~/features/quests/components/address-selector";
import { Quests } from "~/features/quests/eco-quest-container";
import {
  AuthenticatedEcosystemReward,
  UnauthenticatedEcosystemReward,
} from "~/features/rewards/components/ecosystem-reward";
import {
  NoWalletFoundScoreChartContainer,
  ScoreChartContainer,
  UnauthenticatedScoreChartContainer,
} from "~/features/scores/components/score-chart-container";
import { json, useLoaderData } from "~/remix";
import { services } from "~/services/services.server";
import { useEvent } from "~/state/events";
import { LoaderData as OutletContext } from "../__ecosystem";

type LoaderData =
  | {
      __type: "unauthenticated";
      ecosystem: quest.QuestEcosystem;
      scoreChain: score.ScoreChain;
      rewardChain: string;
      quests: quest.QuestWithUserStateAndReward[];
    }
  | {
      __type: "authenticated";
      ecosystem: quest.QuestEcosystem;
      reward: {
        total: number;
        pending: number;
        currency: string;
      };
      quests: quest.QuestWithUserStateAndReward[];
      scoreChain: score.ScoreChain;
      rewardChain: string;
      scores?: score.SnowflakeScore[];
      defaultWallet?: wallet.Address;
    };

export const loader = async ({ params, request }: LoaderFunctionArgs) => {
  const currentUser = await services.auth.getCurrentUser(request);
  const ecosystem = questEcosystemSchema.parse(params.ecosystem);
  const config = questConfigs[ecosystem];
  const scoreChain = scoreChainSchema.parse(config.scoreChain);
  const rewardChain = config.rewardChain;
  if (!currentUser) {
    const quests = await services.quest.getEcoQuestUserStates(ecosystem);
    return json({ __type: "unauthenticated", ecosystem, quests, scoreChain, rewardChain } satisfies LoaderData);
  }

  // score
  const getScore = async () => {
    if (defaultWallet) {
      return await services.scores.getTimeseriesByChainForAddress(scoreChain, defaultWallet.address);
    }
  };

  // wallet
  const wallets = await services.wallets.findByUserId(currentUser.id);
  const defaultWallet = wallets.find((w) => w.chain === scoreChain && w.isDefault);

  const rewardP = services.rewards.getEcosystemRewardTotals(currentUser.id, currentUser.profileId, ecosystem);
  const questsP = services.quest.getEcoQuestUserStates(ecosystem, currentUser.id, wallets);
  const setLastViewedEcosystem = services.quest.setLastViewedEcosystem(currentUser.id, ecosystem);

  const [scores, reward, quests] = await Promise.all([getScore(), rewardP, questsP, setLastViewedEcosystem]);

  return json({
    __type: "authenticated",
    ecosystem,
    defaultWallet,
    reward,
    scoreChain,
    rewardChain,
    scores,
    quests,
  } satisfies LoaderData);
};

const Index = () => {
  const loaderData = useLoaderData<typeof loader>();
  const parentLoaderData = useOutletContext<OutletContext>();
  const ecosystem = loaderData.ecosystem;

  const revalidator = useRevalidator();

  useEvent("GLOBAL.WALLET.SET_DEFAULT_SUCCESS", (e) => {
    // if the default wallet has changed, lets go ahead and reload this page.
    revalidator.revalidate();
  });

  const isAuthenticated = loaderData.__type === "authenticated";
  const hasDefaultWallet = isAuthenticated && loaderData.defaultWallet;

  return (
    <div className="flex flex-col justify-center gap-6 py-4 md:flex-row md:gap-4">
      <aside className="mx-auto max-w-[475px] md:mx-0 md:min-w-[370px] md:basis-1/3">
        <div className="flex h-full flex-col justify-start gap-3">
          <AddressSelector ecosystem={loaderData.scoreChain} />
          {isAuthenticated && hasDefaultWallet && (
            <ScoreChartContainer chain={loaderData.scoreChain} scores={loaderData.scores} />
          )}
          {isAuthenticated && !hasDefaultWallet && (
            <NoWalletFoundScoreChartContainer ecosystem={loaderData.scoreChain} />
          )}
          {!isAuthenticated && <UnauthenticatedScoreChartContainer ecosystem={loaderData.scoreChain} />}
          <div className="rounded-xl border-2 py-5">
            {isAuthenticated && (
              <AuthenticatedEcosystemReward
                ecosystem={ecosystem}
                totalValue={loaderData.reward.total}
                currency={loaderData.reward.currency}
                pendingValue={loaderData.reward.pending}
              />
            )}
            {!isAuthenticated && <UnauthenticatedEcosystemReward ecosystem={ecosystem} />}
          </div>
          <Button
            className="text-gray-80 dark:text-gray-20 dark:bg-shell bg-background border-muted-foreground/20 border"
            variant="special"
            rounded
          >
            <Link className="contents" to={`/earn/quests-v2/history`}>
              Quest History <ArrowRightIcon className="ml-1 h-9 w-4" />
            </Link>
          </Button>
          {isAuthenticated && <PendingRewardsCard />}
          {isAuthenticated && <TotalValueEarnedCard />}
          {parentLoaderData.__type === "authenticated" && <Taxes taxStatus={parentLoaderData.taxStatus} />}
        </div>
      </aside>
      <main className="md:basis-2/3">
        {loaderData.quests.length === 0 && <div className="text-center text-2xl">No quests currently available</div>}
        {loaderData.quests.length > 0 && (
          <Quests
            ecosystem={loaderData.rewardChain}
            quests={loaderData.quests}
            defaultWallet={isAuthenticated ? loaderData.defaultWallet : undefined}
          />
        )}
      </main>
    </div>
  );
};
export default Index;
