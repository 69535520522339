import { wallet } from "@fscrypto/domain";
import { Avatar, Dropdown, Text } from "@fscrypto/ui";
import { Skeleton } from "@fscrypto/ui/skeleton";
import { Link } from "@remix-run/react";
import { Check, ChevronDownIcon } from "lucide-react";
import { useState } from "react";
import { $path } from "remix-routes";
import { useAddWalletModal } from "~/features/wallet-management/state/use-add-wallet-modal";
import { useWalletAddress, useWallets } from "~/features/wallet-management/state/use-wallet";
import { useEvent } from "~/state/events";
import config from "~/utils/config";
import { truncateAddress } from "~/utils/helpers";

export function AddressSelector({ ecosystem }: { ecosystem: string }) {
  const wallet = useWallets();
  if (wallet.isLoading) return <Skeleton className="h-14 w-full" />;
  const walletsByChain = wallet.verifiedWalletsByChain;

  return (
    <>
      {(walletsByChain[ecosystem]?.length ?? 0) > 0 && (
        <div>
          <Link to={$path("/settings/addresses")} className="block pb-1 text-right text-xs text-blue-500">
            Manage Addresses
          </Link>
          <WalletDropdownWrapped ecosystem={ecosystem} />
        </div>
      )}
    </>
  );
}

const WalletDropdownWrapped = ({ ecosystem }: { ecosystem: string }) => {
  const { defaultWalletByChain, verifiedWalletsByChain } = useWallets();
  const addWalletModalState = useAddWalletModal();
  const [isLoading, setIsLoading] = useState(false);

  useEvent("GLOBAL.WALLET.UPSERT_IN_PROGRESS", (e) => {
    if (e.payload.addressUpsert.chain === ecosystem && e.payload.addressUpsert.isDefault) {
      setIsLoading(true);
    }
  });

  useEvent("GLOBAL.WALLET.UPSERT_SUCCESS", (e) => {
    if (e.payload.newAddress.chain === ecosystem && e.payload.newAddress.isDefault) {
      setIsLoading(false);
    }
  });

  const defaultWallet = defaultWalletByChain[ecosystem];
  const ecosystemWallets = verifiedWalletsByChain[ecosystem] ?? [];

  return (
    <Dropdown.Root>
      <Dropdown.Trigger className="w-full">
        <div className="dark:bg-gray-90 dark:text-gray-40 relative w-full cursor-default rounded-md border bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm focus:outline-none sm:text-sm sm:leading-6">
          {isLoading && <Skeleton className="h-6 w-full" />}
          {!isLoading && defaultWallet && <WalletAddress address={defaultWallet} />}
          {!isLoading && !defaultWallet && <span className="text-gray-60 block truncate">{"Select a wallet"}</span>}
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronDownIcon className="text-muted-foreground size-5" aria-hidden="true" />
          </span>
        </div>
      </Dropdown.Trigger>
      <Dropdown.Content align="start" className="z-50 w-full min-w-[300px]">
        {ecosystemWallets.map((w) => {
          return <DropdownItem key={w.id} walletAddress={w} />;
        })}
        <Dropdown.Item
          key={"add"}
          onClick={() => {
            addWalletModalState?.setModalOpen(ecosystem, true);
          }}
        >
          Add a new wallet
        </Dropdown.Item>
      </Dropdown.Content>
    </Dropdown.Root>
  );
};

const DropdownItem = ({ walletAddress }: { walletAddress: wallet.Address }) => {
  const { setDefault } = useWalletAddress(walletAddress.id);
  return (
    <Dropdown.Item onClick={setDefault}>
      <WalletAddressDropdownItem address={walletAddress} isSelected={walletAddress.isDefault} />
    </Dropdown.Item>
  );
};

const WalletAddress = ({ address }: { address: wallet.Address }) => {
  const hasNickname = address.nickname !== null && address.nickname !== "";

  return (
    <div className="flex flex-wrap items-center gap-2">
      <Avatar src={config.CLOUDINARY_PROJECT_ICON_PATH + `${address.chain}.svg`} size="sm" />
      <Text color="contrast">
        {hasNickname ? address.nickname : truncateAddress(address.address, { left: 6, right: 6, maxLength: 24 })}
      </Text>
    </div>
  );
};

const WalletAddressDropdownItem = ({ address, isSelected }: { address: wallet.Address; isSelected?: boolean }) => {
  const hasNickname = address.nickname !== null && address.nickname !== "";

  return (
    <div className="flex w-full flex-row items-center justify-between">
      <div className="flex flex-wrap items-center gap-2">
        <Avatar src={config.CLOUDINARY_PROJECT_ICON_PATH + `${address.chain}.svg`} size="sm" />
        <Text color="contrast">
          {hasNickname ? address.nickname : truncateAddress(address.address, { left: 6, right: 6, maxLength: 24 })}
        </Text>
      </div>
      {isSelected && <Check className="text-blue-40 size-4" />}
    </div>
  );
};
