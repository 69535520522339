import { quest } from "@fscrypto/domain";
import { questConfigs } from "@fscrypto/domain/quest";
import { Card, CardContent, Tag, Text, Tooltip } from "@fscrypto/ui";
import { Link } from "@remix-run/react";
import { capitalize } from "lodash-es";
import { $path } from "remix-routes";
import { ProjectIcon } from "~/features/earn/components/project-icon";
import { tracking } from "~/utils/tracking";
import { QuestStepProgress } from "./eco-step-progress";
import { QuestBucketPill } from "./quest-bucket-pill";

interface Props {
  ecoQuest: quest.QuestWithUserStateAndReward;
  ecosystem?: quest.QuestEcosystem;
}

export const EcoQuestCard = ({ ecoQuest, ecosystem }: Props) => {
  const { quest, rewardOverview, isLoadingRewardOverview } = ecoQuest;

  const step = ecoQuest.userState?.lastViewedStep === undefined ? 0 : ecoQuest.userState.lastViewedStep + 1;
  const ecoAbbreviation = ecosystem ? questConfigs[ecosystem].abbreviation : ecosystem;
  const agentStatus = rewardOverview?.agentStatus ?? null;
  const address = rewardOverview?.address ?? null;

  return (
    <Card className="dark:bg-shell bg-white">
      <Link
        to={$path("/earn/quests-v2/:slug", { slug: quest.slug })}
        onClick={() => tracking(`earn_quest_click`, "Earn", { slug: quest.slug })}
      >
        <CardContent className="flex h-full flex-col justify-between gap-y-9">
          <div className="flex items-center justify-between">
            <div className="flex gap-x-2">
              <ProjectIcon className="h-6 w-6 rounded-full" iconFileName={quest.project.iconFileName ?? ""} />
              <Text className="dark:text-gray-40 text-gray-50">{ecoAbbreviation}</Text>
            </div>
            <QuestStepProgress
              ecosystem={ecosystem}
              currentStep={step}
              totalSteps={quest.steps.length}
              status={agentStatus}
              loading={!!isLoadingRewardOverview}
            />
          </div>
          <div className="flex flex-col gap-y-3">
            <Text size="2xl" className="text-contrast">
              {quest.name}
            </Text>
            <div className="flex flex-wrap gap-1">
              {quest.tags.map((tag) => (
                <Tag key={tag.name} size="xs" variant="outlined" className="dark:bg-transparent" asChild>
                  <Text className="text-gray-70 dark:text-gray-30 whitespace-nowrap" size="xs">
                    {tag.displayName ?? capitalize(tag.name)}
                  </Text>
                </Tag>
              ))}
              {quest.prerequisites?.map((pr) => {
                return (
                  <Tag key={pr.label} size="xs" variant="outlined" className="dark:bg-transparent" asChild>
                    <Text
                      className="bg-gradient-to-l from-[#C03BFF] to-[#5E78FF] bg-clip-text leading-none text-transparent"
                      size="xs"
                    >
                      {pr.label}
                    </Text>
                  </Tag>
                );
              })}
            </div>
          </div>
          <div className="bg-gray-10 dark:bg-gray-80 -mx-2 -mb-2 flex flex-col items-center justify-between gap-y-2 rounded-xl p-4">
            <QuestBucketPill quest={ecoQuest.quest} rewardOverview={ecoQuest.rewardOverview} />
            <div className="flex w-full flex-col">
              <div className="flex w-full">
                <Tooltip content="Connect a wallet to see how much you could earn" side="bottom" display={!address}>
                  <div className="flex flex-row items-center gap-x-2 align-middle">
                    <Text className="dark:text-gray-40 whitespace-nowrap text-gray-50">Rewards Available:</Text>
                    <span
                      className={
                        rewardOverview?.availableSlots === undefined || rewardOverview?.availableSlots === null
                          ? "blur-[3px]"
                          : ""
                      }
                    >
                      <Text className="text-contrast">{rewardOverview?.availableSlots ?? "??"}</Text>
                      <Text className="dark:text-gray-40 text-gray-50">{` / ${rewardOverview?.totalSlots ?? "???"}`}</Text>
                    </span>
                  </div>
                </Tooltip>
              </div>
            </div>
          </div>
        </CardContent>
      </Link>
    </Card>
  );
};
