import { score } from "@fscrypto/domain";
import { useOptionalUser } from "~/utils/auth";
import { scoreChartBaseColor } from "../ecosystem/style-variants";
import { TimeSeriesChartData } from "./components/timeseries-chart";

export const useScoreChart = ({
  scores,
  chain,
}: {
  scores?: score.SnowflakeScore[];
  chain: score.ScoreChain;
}) => {
  const user = useOptionalUser();
  const backgroundColor = scoreChartBaseColor[chain as keyof typeof scoreChartBaseColor] ?? "#FFFFFF";

  const datapoints = scores?.map((score) => {
    return {
      timestamp: new Date(score.scoreDate),
      value: score.totalScore,
      breakdown: score,
    };
  });

  return {
    backgroundColorHex: backgroundColor,
    datapoints: datapoints
      ? datapoints
      : user
        ? []
        : [
            {
              timestamp: new Date(new Date().setMonth(new Date().getMonth() - 1)),
              value: 0,
              breakdown: null,
            },
            {
              timestamp: new Date(),
              value: 0,
              breakdown: null,
            },
          ],
  } satisfies TimeSeriesChartData;
};
